<template>
  <v-main>
    <v-container :v-if="mediaDevices.length>0">
      <v-row>
        <v-col cols="2">
          <v-btn
            v-for="(device, index) in mediaDevices" :key="index" :device="device" @click="setDevice(device)">{{index}}</v-btn>
        </v-col>
        <v-col cols="3">
          <p>{{deviceWidth}} x {{deviceHeight}}</p>
        </v-col>
        <v-col cols="2" sm="2" md="2">
            <v-btn v-if="!match&&isProcessing" color="primary" @click="stopProcessing">Cancel</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="4">
          <v-text-field v-model="internalName" label="Internal"></v-text-field>
        </v-col>
        <v-col cols="2" sm="2" md="2">
            <v-btn color="warning" @click="nextInternal">Next</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-responsive :aspect-ratio="responsiveAspect" @click="scanTag" :style="{ 'padding-bottom': responsivePadding }" >
              <video autoplay="true" id="video" width="100%" @loadedmetadata="handleVideo"></video>
              <div class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                  <polygon v-show="!isProcessing" :points="focusRectanglePoints" stroke="white" stroke-width="2" fill="none"></polygon>
                  <g v-show="reko&&isProcessing&&!match&&!polygons.length">
                    <polygon :points="focusRectanglePoints" stroke="red" stroke-width="15" fill="red" fill-opacity="0.25"></polygon>
                  </g>
                  <g v-show="!(reko&&isProcessing&&!match&&!polygons.length)">
                    <polygon :points="focusRectanglePoints" stroke="white" stroke-width="15" fill="none" fill-opacity="0.25"></polygon>
                  </g>
                  <!--
                  <g v-show="reko&&isProcessing" v-for="(p, index) in polygons" :key="index" :p="p">
                    <polygon :points="p.points" fill="blue" :fill-opacity="p.selected?0.75:0.25" @click="selectText($event, p)" />
                    <text font-size="20" fill="red">{{p.text}}</text>
                  </g>
                  -->
                  <g v-show="match&&isProcessing">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25"></polygon>
                  </g>
                  <g v-show="possibles&&isProcessing">
                    <polygon :points="focusRectanglePoints" fill="none" stroke="orange" stroke-width="15"></polygon>
                  </g>
                </svg>
              </div>
            <canvas id="bytes" style="display: none"></canvas>
          </v-responsive>
        </v-col>
      </v-row>
        <v-row v-if="match&&isProcessing">
          <v-col v-for="(ti, index) in match.tagImages" :key="index" :ti="ti" cols="4">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="ti" width="100%"/>
            </v-responsive>
          </v-col>
        </v-row>
        <v-row v-if="possibles&&isProcessing&&possibles[1]&&possibles[2]">
          <v-col cols="12">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="possibles[0].tagImage" width="100%" @click="acceptPossible(0)"/>
              <div v-if="acceptedPossible===0" class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25" @click="confirmAcceptedPossibleTag(0)"></polygon>
                </svg>
              </div>
            </v-responsive>
          </v-col>
          <v-col cols="6">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="possibles[1].tagImage" width="100%" @click="acceptPossible(1)"/>
              <div v-if="acceptedPossible===1" class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25" @click="confirmAcceptedPossibleTag(1)"></polygon>
                </svg>
              </div>
            </v-responsive>
          </v-col>
          <v-col cols="6">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="possibles[2].tagImage" width="100%" @click="acceptPossible(2)"/>
              <div v-if="acceptedPossible===2" class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25" @click="confirmAcceptedPossibleTag(2)"></polygon>
                </svg>
              </div>
            </v-responsive>
          </v-col>
        </v-row>
        <v-row v-if="possibles&&isProcessing&&possibles[1]&&!possibles[2]">
          <v-col cols="12">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="possibles[0].tagImage" width="100%" @click="acceptPossible(0)"/>
              <div v-if="acceptedPossible===0" class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25" @click="confirmAcceptedPossibleTag(0)"></polygon>
                </svg>
              </div>
            </v-responsive>
          </v-col>
          <v-col cols="12">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="possibles[1].tagImage" width="100%" @click="acceptPossible(1)"/>
              <div v-if="acceptedPossible===1" class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25" @click="confirmAcceptedPossibleTag(1)"></polygon>
                </svg>
              </div>
            </v-responsive>
          </v-col>
        </v-row>
        <v-row v-if="possibles&&isProcessing&&!possibles[1]&&!possibles[2]">
          <v-col cols="12">
            <v-responsive :aspect-ratio="responsiveAspect" :style="{ 'padding-bottom': responsivePadding }" >
              <img :src="possibles[0].tagImage" width="100%" @click="acceptPossible(0)"/>
              <div v-if="acceptedPossible===0" class="svgoverlay">
                <svg width="100%" height="100%" :viewBox="svgViewBox">
                    <polygon :points="focusRectanglePoints" stroke="green" stroke-width="15" fill="green" fill-opacity="0.25" @click="confirmAcceptedPossibleTag(0)"></polygon>
                </svg>
              </div>
            </v-responsive>
          </v-col>
        </v-row>
        <v-row v-if="reko&&isProcessing&&!match&&polygons.length">
          <v-col cols="12" sm="12" md="12">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-btn color="primary" @click="createTag">Add new tag</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(pdt, index) in polygons" :key="index" :pdt="pdt">
              <v-col cols="12" sm="6" md="6" >
                <v-text-field v-if="pdt.selected" disabled label="Unique" :value="pdt.text"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-container>
    </v-main>
</template>

<script>


export default {
  name: 'TakeSnapshot',

  data() {

    return {
      mediaDevices: [],
      isProcessing: false,
      responsiveAspect: "16/9",
      responsivePadding: "56.25%",
      deviceWidth: 1280,
      deviceHeight: 720,

      internal: 0,
      internalName: "Internal 0",

      scanId: null,
      match: null,
      possibles: null,
      reko: null,

      polygons: null,
      videoWidth: 0,
      videoHeight: 0,
      focusRectanglePoints: null, //"0,0 0,0 0,0 0,0",
      svgViewBox: "0 0 0 0",

      acceptedPossible: null

    }
  },

  methods: {

    getScoreBorder: function(score){
      return score>=1?"greenBorder":"redBorder";
    },

    selectText: function(event, p){

      this.polygons[p.detectedTextIndex].selected=!this.polygons[p.detectedTextIndex].selected;
      event.stopPropagation();

    },

    nextInternal: function(){

      this.internal++;

      // Name is internal in hex
      this.internalName = "Internal " + this.internal.toString(16).toUpperCase();
      
    },

    stopProcessing: function(){

      this.scanId = null;
      this.match = null;
      this.possibles = null;
      this.reko = null;

      this.acceptedPossible = null;

      this.isProcessing = false;

      video.play();

    },

    scanTagAPI: async function(width, height){

      const bytesContext = bytes.getContext('2d');

      bytes.width = width;
      bytes.height = height;

      bytesContext.drawImage(video,0,0,width, height);

      // https://stackoverflow.com/questions/13990673/upload-canvas-data-to-s3
      const image = bytes.toDataURL().substring(22);

      let scantag;

      // KGR - we need to add the internalName in this call
      // i.e. this.internalName

      try {

        const url = '/api/tag/scantag';
        const options = {
          method: 'POST', // HTTP method (e.g., GET, POST, PUT, DELETE)
          headers: { // Headers for the request (e.g., Content-Type, Authorization)
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(
            {
              image: image,
              internalName: this.internalName
            }
          )
        };

        const scantagFetch = await fetch(url, options);
        scantag = await scantagFetch.json();

      } catch(error){

        this.stopProcessing();

        this.isProcessing = false;

      }

      return scantag;

    },

    createTag: async function(event){

      const response = {
        scanId: this.scanId,
        serials: this.polygons.filter((p)=>p.selected).map((p)=> {
          return {
            id: p.detectedText.Id,
            text: p.text
          }
        })
      };

      console.log('createTag',response);

      const url = '/api/tag/createtag';
      const options = {
        method: 'POST', // HTTP method (e.g., GET, POST, PUT, DELETE)
        headers: { // Headers for the request (e.g., Content-Type, Authorization)
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(response)
      }

      const createTagFetch = await fetch(url, options);
      const tagId = await createTagFetch.json();
      console.log('tag created with id ', tagId);

      this.stopProcessing();

    },

    acceptPossible: function(p){
        this.acceptedPossible = p;
    },

    confirmAcceptedPossibleTag: async function(event){

      const response = {
        scanId: this.scanId,
        acceptedPossible: this.possibles[this.acceptedPossible],
        acceptedPossibleIndex: this.acceptedPossible, // this way we know if the user selected the best, second best of third best possible
        possibles: this.possibles
      }

      console.log('createTagFromPossible',response);

      const url = '/api/tag/createtagfrompossible';
      const options = {
        method: 'POST', // HTTP method (e.g., GET, POST, PUT, DELETE)
        headers: { // Headers for the request (e.g., Content-Type, Authorization)
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(response)
      }

      const createTagFetch = await fetch(url, options);
      const tagId = await createTagFetch.json();
      console.log('tag created with id ', tagId);
      console.log('confirmAcceptedPossibleTag',response)

      this.stopProcessing();

    },

    scanTag: async function(event){

      if(this.isProcessing){
        return;
      }

      video.pause();

      // Get image data
      const width = video.videoWidth;
      const height = video.videoHeight;

      // start processing
      this.isProcessing = true;

      const scantag = await this.scanTagAPI(width, height);

      // Let's go
      this.scanId = scantag.scanId;
      this.match = scantag.match;
      this.possibles = scantag.possibles;
      this.reko = scantag.reko;

      if(this.match){

        // wait 2,5 seconds and clean up - to allow user to see the match
        setTimeout(() => {
          this.stopProcessing();
          this.isProcessing = false;
        }, 2500);

      } else if(this.reko?.TextDetections){

        let i = 0;

        this.polygons = this.reko.TextDetections.map((td, idx) => {

          // flatten polygon array to string
          const points = td.Geometry.Polygon.reduce((acc, val) => {
            return acc + `${val.X * width},${val.Y * height} `;
          }, '');

          return {
            detectedText: td,
            detectedTextIndex: i++,
            points,
            text: td.DetectedText,
            selected: false,
          }

        });

        // remove low confidence detections
        this.polygons = this.polygons.filter((p) => p.detectedText.Confidence>=0.75);

        if(!this.match&&this.polygons.length===0){

          // red border is shown, wait 3 seconds and cancel 
          setTimeout(() => {
            this.stopProcessing();
            this.isProcessing = false;
          }, 2500);

        }

      } else if(!this.possibles) {

        this.stopProcessing();

        // no text detections,
        this.isProcessing = false;

      }

    },

    setDevice: async function(device) {

      // we're gonny have to jerry rig the buggy behavior of getUserMedia

      const listOfResolutions = [
        { width: 1920, height: 1440 },
        { width: 1920, height: 1080 },
        { width: 1920, height: 1080 },        
        { width: 1600, height: 1200 },
        { width: 1600, height: 900 },
        { width: 1280, height: 960 },
        { width: 1280, height: 720 },
        { width: 640, height: 480 },
      ];

      // try to get the user media that fits the largest resolution

      let srcObject;

      // loop through list of resolutions
      for(let i=0; i<listOfResolutions.length; i++){

        if(!srcObject){

          // get the resolution
          const resolution = listOfResolutions[i];

          try {

            srcObject = await navigator.mediaDevices.getUserMedia(
            {
              video: {
                deviceId: device.deviceId,
                width: { min: resolution.width },
                height: { min: resolution.height }
              }
            });


          } catch {

            srcObject = null;
            // do nothing
            
          }

        }

      }

      if(srcObject===null){
        alert('Could not get video stream');
        return;
      }

      const video = document.getElementById('video');

      video.srcObject = srcObject;

    },

    handleVideo: function(event) {

      // video element
      const video = event.target;

      const focusMargin = 50;

      // video width and height
      this.videoWidth  = video.videoWidth;
      this.videoHeight = video.videoHeight;

      this.deviceHeight = this.videoHeight;
      this.deviceWidth = this.videoWidth;

      this.responsiveAspectRatio = this.deviceWidth / this.deviceHeight;
      this.responsivePadding = `${(this.deviceHeight / this.deviceWidth) * 100}%`;

      console.log(`Device resolution ${this.deviceWidth} x ${this.deviceHeight}`);

      this.focusRectanglePoints = `${focusMargin},${focusMargin} ${this.videoWidth-focusMargin},${focusMargin} ${this.videoWidth-focusMargin},${this.videoHeight-focusMargin} ${focusMargin},${this.videoHeight-focusMargin}`;

      this.svgViewBox = `0 0 ${this.videoWidth} ${this.videoHeight}`;

    },

  },

  mounted: function() {
    this.$nextTick(async function() {

      // media device selection

      if(navigator.mediaDevices){

        if(navigator.mediaDevices.getUserMedia){

          const x = await navigator.mediaDevices.getUserMedia({video: true, audio: false});

          const mediaDevices = await navigator.mediaDevices.enumerateDevices();

          if(mediaDevices.length > 1){

            this.mediaDevices = mediaDevices.filter(md => {
              return md.kind == 'videoinput';
            });

            this.setDevice(this.mediaDevices[0]);

          } else {

            this.setDevice(mediaDevices[0]);

          }

          // in case of resize of window adjust video size and rotate in case width is smaller than height
          // window.addEventListener('resize', this.handleResize, false);

        }

      }

    });
  },

  // beforeDestroy: function() {
  //   //window.removeEventListener('resize', this.handleResize, false);
  // }

}
</script>

<style local>

.v-responsive {
  height: 0;
}

.svgoverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;}

.redBorder {
  border: 10px red;
}

.greenBorder {
  border: 10px green;
}

</style>

