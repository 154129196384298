<template>
  <take-snapshot />
</template>

<script>
import TakeSnapshot from '../components/TakeSnapshot.vue'

export default {
  name: 'HomeView',

  components: {
    TakeSnapshot
  }
}
</script>
