import { render, staticRenderFns } from "./TakeSnapshot.vue?vue&type=template&id=2ab11dec&"
import script from "./TakeSnapshot.vue?vue&type=script&lang=js&"
export * from "./TakeSnapshot.vue?vue&type=script&lang=js&"
import style0 from "./TakeSnapshot.vue?vue&type=style&index=0&id=2ab11dec&prod&local=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports